import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Button } from "@material-ui/core";

import qs from 'qs';
import * as api from "./constants/api";
import NavMenu from "../src/components/NavMenu";
import formatDate from "./util/Date";
import axios from "axios";
import { adalApiFetch } from "./adalConfig";
import { Link } from "react-router-dom";
import "./styles/RegSearch.scss";
import AuditTrailPopUp from "./components/auditTrail";
import {  Input, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import 'antd/dist/reset.css'; 
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Col, Row } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { toastError } from "../src/util/CommonFunctions";
import {json2excel} from 'js2excel';

import
{ DatePicker }
from
"antd"
;
const { RangePicker } = DatePicker;


const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,   
    width: '5%',
  },
  {
    title: 'Entilement',
    dataIndex: 'entitlement',   
    width: '25%',
  },
  {
    title: 'Type',
    dataIndex: 'licenseTypeName',   
    width: '15%',
  },
  {
    title: 'Family',
    dataIndex: 'productFamilyName',   
    width: '15%',
  },
  {
    title: 'Email',
    dataIndex: 'emailID',
  },
  {
    title: 'Account Name',
    dataIndex: ['companyDetail','companyName'],
  },
  {
    title: 'Status',
    dataIndex: 'requestStatusName',
  },
  {
    title: 'Date',
    dataIndex: 'creationTime',
  },
  {
    title: 'Action',
    key: 'action',
  
    render: (_, record) => (
       <div className='flex'>

  
          <Link className=""
               
                 to={{
                   pathname: "/detail/" +record.id,
                   state: { assignView: record.requestStatusId === 2 ? true : false },
                 
                 }}
               >
                  <Button className='btn small auditBtn' style={{marginLeft:'0px',color:'white',backgroundColor:'#76b200',height:'80%',padding:'.5em'}}
                
                  
                >
                  { record.requestStatusId === 2 ? "Match" : "View"}
                </Button>
                
               </Link>
       
      
       </div>
    ),
  },
];


const RegistrationSearch = () => {

  


    const [pagination, setPagination] = useState({});
    // const [tableParams, setTableParams] = useState({
    //     pagination: {
    //       current: 1,
    //       pageSize: 10,
    //     },
    //   });
      const [data, setData] = useState();
      useEffect(() => {
       
         fetchData(10,0,true,true,false);
         getLookups();
       }, []);
      
   
    const [isInitial, setInitial] = useState(true);
   
    const [regID, setRegID] = useState();

    const [LicenseTypes, setLicenseTypes] = useState();
    const [FamilyTypes, setFamilyTypes] = useState();
    const [Statuses, setStatuses] = useState();
    const [ClaimTypes, setClaimTypes] = useState();

    const [pakid, setPakID] = useState();
    const [emailID, setEmailID] = useState();
    const [accountName, setAccountName] = useState();
    const [statusValue, setStatusValue] = useState();

    const [licenseTypeValue, setLicenseValue] = useState();
    const [licenseTypeLabel, setLicenseLabel] = useState();
    const [productFamilyValue, setFamilyValue] = useState();
    const [productFamilyLabel, setFamilyLabel] = useState();
    const [dateRange, changeDateRange] = useState(null);
    const [dateFromValue, setdateFromValue] = useState();
    const [dateToValue, setdateToValue] = useState();

    const onDateRangeChange=(dateRange)=>{
     debugger;
 
    dayjs.extend(customParseFormat)
    if(dateRange &&dateRange[0])
    {
        const dateFrom = dayjs(dateRange[0], 'MM/DD/YYYY').toLocaleString();
        setdateFromValue(dateFrom);
    } 
    else{
        setdateFromValue();
    }
     if(dateRange&&dateRange[1])
     {
        const dateTo = dayjs(dateRange[1], 'MM/DD/YYYY').toLocaleString();
     
        setdateToValue(dateTo);
     }
     else   {
        setdateToValue();
     }
     if (dateRange &&dateRange[0]) {
        changeDateRange([ dayjs(dateRange[0], 'MM/DD/YYYY'),null]);
     } 
     
     if (dateRange &&dateRange[1]) {
        changeDateRange([null, dayjs(dateRange[1], 'MM/DD/YYYY'),null]);
     } 
     if(!dateRange)
     {
        changeDateRange([null,null]);
     }
   
     }
    const handleProductFamilyChange = (value) => {
        
        setFamilyValue(FamilyTypes.filter(data => data.value === value)[0].key);
        setFamilyLabel(value);
      };
      const handleLicenseTypeChange = (value) => {
       
     
        setLicenseValue(LicenseTypes.filter(data => data.value === value)[0].key);
        setLicenseLabel(value);
      };




    const handleStatusChange = (value) => {
        
        setStatusValue(value);
      };
    const handlesetAccountNameChange = (e) => {
        setAccountName(e.target.value);
      };
    const handleEmailIDChange = (e) => {
        setEmailID(e.target.value);
      };
    const handleRegIDChange = (e) => {
        setRegID(e.target.value);
      };
      const handlePAKIDIDChange = (e) => {
        setPakID(e.target.value);
      };
      const searchOnClick = () => {
           
            setInitial(false);  
            fetchData(true,true,true,true,true,false);
            setPagination({current:1,defaultCurrent:1});        
       
      }
      const searchBlurClick = () => {
           
        setInitial(false);  
        fetchData(true,true,true,true,true,false);
        debugger;
        setPagination({current:1,defaultCurrent:1});        
   
  }
    
      const clearFilterOnClick = () => {
           
        setInitial(true);  
        fetchData(true,true,true,true,true,true);
        setPagination({current:1});  
       
        
       

       // this.forceUpdate();

   
  }
  const clearFields = () => {
   
  setRegID('');
  setPakID('');
  setEmailID('');
  setAccountName('');
  setLicenseValue('');
  setLicenseLabel('');
  setFamilyValue('');
  setFamilyLabel('');
  setStatusValue('');
  setdateFromValue(null);
  setdateToValue(null);
  changeDateRange([null,null]);
  };
  const [loading, setLoading] = useState(false);
 
  const  getLookups=() =>{
   
    adalApiFetch(axios, api.GET_LOOKUPS, {})
      .then((response) => {
        debugger;
        response.data.LicenseType.unshift({ key: 0, value: "All" });
        response.data.ProductFamily.unshift({ key: 0, value: "All" });
        response.data.Status.unshift({ key: -1, value: "In progress" });
        response.data.Status.unshift({ key: 0, value: "All" });
        response.data.ClaimType.unshift({ key: 0, value: "All" });
         setLicenseTypes(response.data.LicenseType);
     setFamilyTypes(response.data.ProductFamily);
     setStatuses(response.data.Status);
    setClaimTypes(response.data.ClaimType);
      
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
        }
      });
  }
  const buildFilter=()=>{
  
   
        let filterString = "";
        if (regID) {
               if (filterString !== "") {
                filterString += "&&";
               }
              filterString +=
                 "id=" + JSON.stringify(regID);
              
             }
                  if (pakid) {
           if (filterString !== "") {
             filterString += "&&";
           }
           filterString +=
           "PakId.Contains(" + JSON.stringify(pakid)+")";
            
        }

     if (emailID) {
           if (filterString !== "") {
            filterString += "&&";
          }
           filterString +=
             "EmailID.Contains(" + JSON.stringify(emailID)+")";
         }
   if (accountName) {
          if (filterString !== "") {
            filterString += "&&";
           }
          filterString +=
            "CompanyDetail.CompanyName.Contains(" +
            JSON.stringify(accountName)+")";
         }

         if (statusValue && statusValue!=="All" ) {
            if (filterString !== "") {
              filterString += "&&";
            }
           
         
            filterString +=
            "RequestStatusName=" + JSON.stringify(statusValue);
          }

   if (licenseTypeValue && licenseTypeValue!=="All") {
          if (filterString !== "") {
             filterString += "&&";
           }
           filterString +=
           "LicenseTypeId=" + licenseTypeValue;
         }
        if (productFamilyValue  && productFamilyValue!=="All") {
           if (filterString !== "") {
             filterString += "&&";
          }
          filterString +=
          "ProductFamilyId=" +productFamilyValue;
        }
        if (dateFromValue) {
            if (filterString !== "") {
              filterString += "&&";
            }
            filterString +=
              "CreationTime.Date>=" +
              JSON.stringify(dateFromValue);
          }
          if (dateToValue) {
            if (filterString !== "") {
              filterString += "&&";
            }
            filterString +=
              "CreationTime.Date<=" + JSON.stringify(dateToValue);
          }


      
        return filterString;
  }
  const  getReportData=()=> {
  
  
    let filterString = buildFilter();
    
   
    
    

    let options = {
      params: {
        //take: this.state.Take,
       filter:filterString
      },
    };

   
    adalApiFetch(axios, api.GET_REPORTDATA, options) //testData.json api.GET_REGISTRATIONS
      .then((response) => {
       
        var newData = [];
if(response.data.records.length>0)
{


        for (var i = 0; i < response.data.records.length; i++) {
    
            if (response.data.records[i].id ) {
              
                newData.push({
                  'RegID': response.data.records[i].id,
                  'EmailID': response.data.records[i].emailID,
                  'LicenseTypeName': response.data.records[i].licenseTypeName,
                  'ProductFamilyName': response.data.records[i].productFamilyName,
                  'CompanyName': response.data.records[i].companyDetail?response.data.records[i].companyDetail.companyName:"",
                  'Status': response.data.records[i].requestStatusName,
                 'Error': response.data.records[i].exceptionMessage?response.data.records[i].exceptionMessage:"",
                 'ISEvalToCommerConversion':response.data.records[i].isEvalToCommerConversion,
                 'ISLGSAddUser':response.data.records[i].isLGSAddUser,
                 'ClaimTypeName':response.data.records[i].claimTypeName?response.data.records[i].claimTypeName:"",
                 'Entitlement':response.data.records[i].pakid?response.data.records[i].pakid:"",
                 'CertifiedServerName':response.data.records[i].certifiedServerName?response.data.records[i].certifiedServerName:"",
                 'VDIHyperVisorName':response.data.records[i].vdiHyperVisorName?response.data.records[i].vdiHyperVisorName:"",
                 'NvidiaGPUName':response.data.records[i].nvidiaGPUName?response.data.records[i].nvidiaGPUName:"",
                 'VDISeatName':response.data.records[i].vdiSeatName?response.data.records[i].vdiSeatName:"",
                 'VDIRemotingClientName':response.data.records[i].vdiRemotingClientName?response.data.records[i].vdiRemotingClientName:"",
                 'PrimaryApplicationName':response.data.records[i].primaryApplicationMulName?response.data.records[i].primaryApplicationMulName:"",
                 'Date':response.data.records[i].creationTime?formatDate(new Date(response.data.records[i].creationTime)):""
                });
              }
        
      
            }
          
           
           
           const data=(JSON.parse(JSON.stringify(newData)));
         
         json2excel({ data, name: 'Report', formateDate: 'yyyy/mm/dd' })       

          }
          else
          {
            toastError("No Records Found");
          }
      })
      .catch((error) => {
        
        console.log(error.response);
        toastError(error.response);
      });
  }
  const fetchData = (takes,skips,sortfields,sortorders,isSearch,isClear) => {
    
   
    
        setLoading(true);
      
      //  let aa=qs.stringify(getRandomuserParams(tableParams));
        let fil=buildFilter();
        if(isClear)
        {
            fil='';
        }
        let options = {
            params: {
              //take: this.state.Take,
              filter: fil,
              take:(takes.results)?takes.results:10,
              skip:(takes.results*takes.page-takes.results)?takes.results*takes.page-takes.results:0,
              orderby:'id desc'
          
            },
          };
         
     
              adalApiFetch(axios, api.GET_REGISTRATIONSQUICKSEARCH, options) //testData.json api.GET_REGISTRATIONS
                .then((response) => {
                 
                  const modifiedData = response.data.records.map((r) => {
                    r.creationTime = formatDate(new Date(r.creationTime));
                    r.creationTimeTo = formatDate(new Date(r.creationTime));
                    r.creationTimeFrom = formatDate(new Date(r.creationTime));
                    r.entitlement = r.pakid && r.pakid !== "" ? r.pakid : r.serialNumber; 
                    return r;
                })     
                    setData(modifiedData);
                    setLoading(false);
                    console.log(isInitial);
                    isSearch? setPagination({
                        total: response.data.totalCount,
                        current:1,
                        pageSize:10
                      
                    }):
                    setPagination({
                        total: response.data.totalCount,
                        
                        
                    });
                 
                  
                 
          
               
                }).then(()=>{if(isClear)
                    {                    
                    clearFields()}
  })
                .catch((error) => {
                  console.log(error.response);
                
                });
    
   

  };


  const handleTableChange = (pagination, filters, sorter) => {
   
    console.log(pagination, sorter);
    setPagination(pagination);
    fetchData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      isSearch:false,
     
    });
 
  };
  return (
    <div>
        { <NavMenu />}
       <Divider orientation="left" plain>
       <Tag color="rgb(118, 178, 0)"> Custom Search</Tag>
     
      </Divider>
        <div>
        
            <Row>
          
           
            {/* <Col style={{marginLeft:'10px'}} span={1}>
            <span >Reg ID:</span>
            </Col> */}
            <Col  span={4}>
             <Input value={regID}   onBlur={searchBlurClick} addonBefore="Registration ID" onChange={handleRegIDChange}     />
    
    </Col>
    <Col style={{marginLeft:'10px'}} span={4}>
    
      <Input onChange={handlePAKIDIDChange}  addonBefore="Entitlement" value={pakid} onBlur={searchBlurClick}   />
   
    </Col>
    <Col style={{marginLeft:'10px'}} span={4}>
    
      <Input onChange={handleEmailIDChange}  addonBefore="Email"   onBlur={searchBlurClick} value={emailID}  />
   
    </Col>
    <Col style={{marginLeft:'10px'}} span={4}>
   
      <Input onChange={handlesetAccountNameChange}  addonBefore="Account Name"   onBlur={searchBlurClick} value={accountName}  />
    
    </Col>
    </Row>
 <Row  style={{margin:'10px'}}></Row>
  
          
           <Row>
           <Col style={{marginLeft:'10px'}} span={4}>
           <Tag  >License Type: </Tag>
    <Select  style={{ width: 150 }}
   value={licenseTypeLabel}
    placeholder="Select License type"
    
    onChange={handleLicenseTypeChange}
 
    
    options={LicenseTypes}
  />
       </Col>
       <Col style={{marginLeft:'10px'}} span={4}>
       <Tag  >Product Family: </Tag>
    <Select
  style={{ width: 150 }}
    placeholder="Select Product Family"
    
    onChange={handleProductFamilyChange}
 value={productFamilyLabel}
    
    options={FamilyTypes}
  />
       </Col>
       <Col style={{marginLeft:'10px'}} span={4}>
       <Tag  >Request Status: </Tag>
    <Select
    style={{ width: 150 }}
    placeholder="Select Status"
    value={statusValue}
    onChange={handleStatusChange}
 
    
    options={Statuses}
  />
     </Col>
     <Col style={{marginLeft:'10px'}} span={6}>
     <Tag  >Date Filter: </Tag>
    <RangePicker 
    allowEmpty={[true, true]}
     style={{ width: 150 }}
    value={dateRange !== "" ? dateRange : ""}
      onChange={onDateRangeChange} format="MM/DD/YYYY" />
   

   </Col>
 
      </Row>
     
         <Row style={{marginTop:'20px'}}>
         <Col style={{marginLeft:'10px'}} span={2}>
           <Button onClick={searchOnClick}  className='btn small auditBtn' style={{marginLeft:'0px',color:'white',backgroundColor:'#76b200',height:'80%'}}  type="primary" icon={<SearchOutlined />}>
        Search
      </Button>
      </Col>
      <Col style={{marginLeft:'10px'}} span={2}>
           <Button onClick={clearFilterOnClick}  className='btn small auditBtn' style={{marginLeft:'20px',color:'white',backgroundColor:'#76b200',height:'80%'}}  type="primary" >
        Clear Filters
      </Button>
      </Col>
      <Col style={{marginLeft:'10px'}} span={2}>
           <Button  variant='contained'
          size='small'
        
          onClick={getReportData}
           className='btn small auditBtn' style={{marginLeft:'20px',color:'white',backgroundColor:'#76b200',height:'80%'}}  type="primary" >
       Download Report
      </Button>
      </Col>
      <Col  span={14}></Col>
         </Row>
            <Divider />
        </div>
     
    <Table
    size="middle"
    rowClassName={() => "rowClassName1"}
    bordered
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data}
     // pagination={current={current}, defaultPageSize={pageSizeRef.current}, onChange={handlePageSizeChange}}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
    />
    </div>
  );
};
export default RegistrationSearch;