import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
  tenant: "43083d15-7273-40c1-b7db-39efd9ccc17a",
  clientId: "f9b851d7-2973-4bc7-bdba-315df58483c8",
  endpoints: {
    api: "f9b851d7-2973-4bc7-bdba-315df58483c8"
  },
  cacheLocation: "sessionStorage",
  extraQueryParameter: "domain_hint=nvidia.com",
  //redirectUri: "#{Licensing.Admin.Api.Url}"
//redirectUri:  "http://localhost:5000/",
//redirectUri:  "https://epradmin-stg.nvidia.com/",
redirectUri:  "https://epradmin.nvidia.com/",
 //redirectUri:  "https://epradminelevate-stg.nvidia.com/",
 
};

if (adalConfig.redirectUri.indexOf("Licensing.Admin.Api.Url") > -1) {
 //adalConfig.redirectUri = "http://localhost:5000/";
//adalConfig.redirectUri = "https://epradmin-stg.nvidia.com/";
 adalConfig.redirectUri = "https://epradmin.nvidia.com/";
 
}

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
