import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import * as api from "./constants/api";
import { adalApiFetch } from "./adalConfig";
import axios from "axios";
import AppHeader from "./AppHeader";
import RegistrationList from "./RegistrationList";
import RegistrationSearch from "./RegistrationSearch";
import PcustRegList from "./components/pcustRegistrations/PcustRegList";
import PcustMatching from "./components/pcustRegistrations/PcustMatching";

import RegistrationDetail from "./RegistrationDetail";
import RWAMappingList from "./components/rwamapping/RWAMappingList";

//import RWAGroupList from "./components/rwagroup/RWAGroupList";
//import IDMGroupList from "./components/idmgroup/IDMGroupList";
import LicenseTypeList from "./components/licensetype/LicenseTypeList";
import ProductFamilyList from "./components/productfamily/ProductFamilyList";

import NotFound from "./NotFound";
import "./styles/App.scss";
//import Landing from "./Landing";
//import RWALanding from "./RWALanding";
import * as accessTypes from "./constants/accessTypes";
import Unauthorized from "./Unauthorized";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSync,
  faTimes,
  faSpinner,
  faUndo,
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

class App extends Component {
  constructor(props) {
    super(props);

    library.add([faSync, faTimes, faSpinner, faUndo, faEdit, faPlus, faTrash]);
  }

  state = {
    AccessAreas: [],
    IsLoading: true,
  };

  componentDidMount() {
    this.getAuthorization();
  }

  render() {
    if (this.state.IsLoading) {
      return <div>loading</div>;
    }
    const { AccessAreas } = this.state;
    const canViewReg =
      AccessAreas.filter((x) => x === accessTypes.VIEW_REGISTRATION).length ===
      1;
    const canProcessReg =
      AccessAreas.filter((x) => x === accessTypes.PROCESS_REGISTRATION)
        .length === 1;
    const canViewCustAssign =
      AccessAreas.filter((x) => x === accessTypes.VIEW_CUSTOMER_ASSIGNMENT)
        .length === 1;
    const canCreateCust =
      AccessAreas.filter((x) => x === accessTypes.CREATE_CUSTOMER).length === 1;
    const canAssignCust =
      AccessAreas.filter((x) => x === accessTypes.ASSIGN_CUSTOMER).length === 1;
    const canViewRWAMapping =
      AccessAreas.filter((x) => x === accessTypes.VIEW_RWA_MAPPING).length ===
      1;
    const canCreateRwaMapping =
      AccessAreas.filter((x) => x === accessTypes.CREATE_RWA_MAPPING).length ===
      1;

    const viewCancelStatus =
      AccessAreas.filter((x) => x === accessTypes.VIEW_CANCEL_STATUS).length ===
      1;
      const userNTAccount =this.state.AccessAreas[this.state.AccessAreas.length-1]

    if (!canViewReg) {
      return (
        <div className='app'>
          <AppHeader includeNav={false} />
          <Unauthorized />
        </div>
      );
    }

    return (
      <Router>
        <div className='app'>
          <Route path='/' component={AppHeader} />
          <Switch>
            <Route
              exact
              path='/registrationsearch'
              render={(props) => (
                <RegistrationList
                  {...props}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />
     <Route
              exact

              path='/' 
              render={(props) => (
                <RegistrationSearch
                  {...props}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />
            {/* <Route path="/all" component={RegistrationList} /> */}
            <Route
              path='/pending'
              render={(props) => (
                <RegistrationList
                  {...props}
                  statusFilter={"RequestStatusId=2"}
                  statusDisplay={"PCUST"}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />
            <Route
              path='/failed'
              render={(props) => (
                <RegistrationList
                  {...props}
                  statusFilter={"RequestStatusId=7"}
                  statusDisplay={"ERROR"}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />
              <Route
              path='/pcustassignment'
              render={(props) => (
                <PcustRegList
                {...props}
                canViewRWAMapping={canViewRWAMapping}
                canCreateRWAMapping={canCreateRwaMapping}
                userName={userNTAccount}
                />
              )}
            />
              <Route
              path='/pcustmatching'
              render={(props) => (
                <PcustMatching
                {...props}
                statusFilter={"RequestStatusId=2"}
                canViewRWAMapping={canViewRWAMapping}
                canCreateRWAMapping={canCreateRwaMapping}
                canProcessReg={canProcessReg}
                canViewCustAssign={canViewCustAssign}
                canCreateCust={canCreateCust}
                canAssignCust={canAssignCust}
                viewCancelStatus={viewCancelStatus}                     
                userName={userNTAccount}
                />
              )}
            />
            <Route
              path='/detail/:id'
              render={(props) => (
                <RegistrationDetail
                  {...props}
                  canProcessReg={canProcessReg}
                  canViewCustAssign={canViewCustAssign}
                  canCreateCust={canCreateCust}
                  canAssignCust={canAssignCust}
                  viewCancelStatus={viewCancelStatus}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />

            <Route
              path='/lictype'
              render={(props) => (
                <LicenseTypeList
                  {...props}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />

            <Route
              path='/prodfamily'
              render={(props) => (
                <ProductFamilyList
                  {...props}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />

            <Route
              path='/rwamapping'
              render={(props) => (
                <RWAMappingList
                  {...props}
                  canViewRWAMapping={canViewRWAMapping}
                  canCreateRWAMapping={canCreateRwaMapping}
                />
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }

  getAuthorization() {
    adalApiFetch(axios, api.GET_AUTHORIZATION, {})
      .then((response) => {

        this.setState({ AccessAreas: response.data, IsLoading: false });
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
        }
      });
  }
}

export default App;
